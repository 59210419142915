import { useState } from "react"
import { useStreams } from "./providers/providers"

interface StreamData {
    date: string
    description: string,
    shows: ShowData[]
}

interface ShowData {
    title: string,
    description?: string
    sc_url?: string,
    img_url: string,
}

export const RecordingsScreen = () => {
    const streams = useStreams()

    return (
        <div className="Recordings-screen">
            {streams?.map((stream: StreamData) => <StreamComponent {...stream} />)}
        </div>
    )
}

const StreamComponent = ({ description, date, shows }: StreamData) => {
    return (
        <div className="Recordings-stream">
            <div className="Recordings-stream-title">{date}</div>
            <div className="Recordings-stream-content">
                {shows.map((show) =>
                    <RecordingsComponent {...show} />
                )}
            </div>
        </div>
    )
}

const RecordingsComponent = ({ title, description, sc_url, img_url }: ShowData) => {

    const [expand, setExpand] = useState(false);
    const embed_url = 'https://w.soundcloud.com/player/?url=' + sc_url + '&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true';

    return (
        <div className={expand ? "Recordings-mix-expanded" : "Recordings-mix"} onClick={() => setExpand(!expand)}>
            {
                expand ?
                    (
                        <div>
                            <div>
                                <iframe title={title} width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src={embed_url}></iframe>
                                <div className="style1">
                                </div>
                            </div>
                            <div>
                                <text>{description}</text>
                            </div>
                        </div>) :
                    <div style={{ width: "100%" }}>
                        <img className="Recordings-mix-img" src={img_url} alt="img" style={{ width: "100%" }}></img>
                    </div>
            }
            {/* <div className="Recordings-mix-title">{mix.title}</div> */}
        </div>
    )
}
